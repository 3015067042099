import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ActionDropdown = React.memo(({color, className, options, onClick}) => {

  return (
    <>
      <UncontrolledDropdown className="dropdown d-inline-block">
        <DropdownToggle className="btn btn-soft-secondary btn-sm p-0" tag="button">
          <i 
            style={{color: color}}
            className={className} 
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {options?.map((item, index) => (
            <DropdownItem key={index} onClick={() => onClick(item)}>
              <i style={{color: item.color}} className={item.className} /> {item?.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
})

export default ActionDropdown;
