import { createSlice } from "@reduxjs/toolkit";
import { initialFieldStatusConstant } from "../../utils/constants";

export const applicationAction = createSlice({
  name: 'applicationAction',
  initialState: {
    activeApplicationIndex: 0,
    applicationList: [],
    additionalDocList: [],
    data: {},
    loading: false,
    error: null,
  },
  reducers: {
    updateActiveApplicationIndex: (state, action) => {
      state.activeApplicationIndex = action?.payload
    },
    updateApplicationList: (state, action) => {
      state.applicationList = action?.payload;
    },
    updateApplicationAction: (state, action) => {
      state.loading = true;
    },
    updateApplicationActionSuccess: (state, action) => {
      state.loading = false;
      state.data = action?.payload
    },
    updateApplicationActionError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getAdditionalDocList: (state, action) => {

    },
    getAdditionalDocListSuccess: (state, action) => {
      state.additionalDocList = action?.payload;
    },
    getAdditionalDocListError: (state, action) => {
      state.error = action?.payload;
    }
  }
});



export const applicationActionFields = createSlice({
  name: 'applicationActionFields',
  initialState: {
    reKycFields: [],
    taskFields: [],
    additionalDocs: [],
    fieldStatus: initialFieldStatusConstant,
    loading: false,
    error: null
  },
  reducers: {
    updateRekycFields: (state, action) => {
      if(state.reKycFields?.some((obj) => (obj?.field == action?.payload?.field))){
        return;
      }
      if(state.taskFields?.some((obj) => (obj?.field == action?.payload?.field))){
        const key = action?.payload?.field;
        const newArr = state.taskFields?.filter((obj) => (obj?.field != key));
        state.taskFields = newArr;
      }
      state.reKycFields = [...state.reKycFields, action?.payload];
      state.fieldStatus = {...state?.fieldStatus, [action?.payload?.field]: action?.payload?.code};
    },
    updateTaskFields: (state, action) => {
      if(state.taskFields?.some((obj) => (obj?.field == action?.payload?.field))){
        return;
      }
      if(state.reKycFields?.some((obj) => (obj?.field == action?.payload?.field))){
        const key = action?.payload?.field;
        const newArr = state.reKycFields?.filter((obj) => (obj?.field != key));
        state.reKycFields = newArr;
      }
      state.taskFields = [...state.taskFields, action?.payload];
      state.fieldStatus = {...state?.fieldStatus, [action?.payload?.field]: action?.payload?.code};
    },
    updateAdditionalDocFields: (state, action) => {
      state.additionalDocs = [...state.additionalDocs, action?.payload];
    },
    removeReKYCField: (state, action) => {
      const key = action?.payload;
      const newArr = state.reKycFields?.filter((obj) => (obj?.field != key));
      state.reKycFields = newArr;
      state.fieldStatus = {...state?.fieldStatus, [key]: 'PENDING'};
    },
    removeTaskField: (state, action) => {
      const key = action?.payload;
      const newArr = state.taskFields?.filter((obj) => (obj?.field != key));
      state.taskFields = newArr;
      state.fieldStatus = {...state?.fieldStatus, [key]: 'PENDING'};
    },
    removeAdditionalDocField: (state, action) => {
      const key = action?.payload;
      const newArr = state.additionalDocs?.filter((obj) => (obj?.field != key));
      state.additionalDocs = newArr;
    },
    clearREKYCTaskAdditionalDocs: (state, action) => {
      state.reKycFields = [];
      state.taskFields = [];
      state.additionalDocs = [];
    },
    getApplicationFieldStatus: (state, action) => {
      state.loading = true;
      state.fieldStatus = initialFieldStatusConstant;
    },
    getApplicationFieldStatusSuccess: (state, action) => {
      state.fieldStatus = {...state?.fieldStatus, ...action?.payload};
      state.loading = false;
    },
    getApplicationFieldStatusError: (state, action) => {
      state.loading = false;
      state.error = action?.payload
    }
  }
})

export const saasAction = createSlice({
  name: 'saasAction',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    updateSaasApplicationStatus: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateSaasApplicationStatusSuccess: (state, action) => {
      state.loading = false;
    },
    updateSaasApplicationStatusError: (state, action) => {
      state.loading = false;
      state.error = action?.payload
    }
  }
})


export const { 
  updateActiveApplicationIndex,
  updateApplicationList,
  updateApplicationAction, 
  updateApplicationActionSuccess, 
  updateApplicationActionError,
  getAdditionalDocList,
  getAdditionalDocListSuccess,
  getAdditionalDocListError,
} = applicationAction?.actions;
export const { 
  updateRekycFields, 
  updateTaskFields,
  updateAdditionalDocFields,
  getApplicationFieldStatus,
  getApplicationFieldStatusSuccess,
  getApplicationFieldStatusError,
  removeReKYCField,
  removeTaskField,
  removeAdditionalDocField,
  clearREKYCTaskAdditionalDocs
} = applicationActionFields?.actions; 
export const {
  updateSaasApplicationStatus,
  updateSaasApplicationStatusSuccess,
  updateSaasApplicationStatusError
} = saasAction?.actions;

export const applicationActionReducer = applicationAction?.reducer;
export const applicationActionFieldsReducer = applicationActionFields?.reducer;
export const saasReducer = saasAction?.reducer;