import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate, formatDate3, productNameMapping } from '../../utils/helper';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import CustomUncontrolledDropdown from '../../components/Dropdown/CustomUncontrolledDropdown';
import { useSelector } from 'react-redux';
import useClass from '../../hooks/useClass';
import { getTeamList } from '../../store/admin/adminData';


const Columns = () => {

    const { toaster, adminServer, dispatch, permission } = useClass();
    const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        let timeOut;
        timeOut = setTimeout(() => {
            if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
                dispatch(getTeamList({search:searchQuery}));
            }
        }, 500);

        return () => {
          clearTimeout(timeOut)
        }
    }, [searchQuery]);

    const handleTeamAssignment = async (team, applicationId) => {
        try {
          if(permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER){
            const result = await toaster.confirmation('Assign', `Assign ${applicationId} to ${team?.name}`);
            if(result.isConfirmed){
              const response = await adminServer.assignApplication({teamId: team?.id, applicationIds: [applicationId]});
              if(response?.data?.success){
                toaster.show(false, 'Assigned Successfully');
                window.location.reload();
              } else {
                throw new Error(response?.data?.message);
              }
            }
          } else {
            throw new Error('You dont have permission');
          }
        } catch(error) {
          toaster.show(true, error?.message);
        }
    }

    const handleUserAssignment = async (user, applicationId, teamId) => {
        if(permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER){
          const result = await toaster.confirmation('Assign', `Assign ${applicationId} to ${user?.name}`);
          if(result?.isConfirmed){
            try {
                const response = await adminServer.assignApplication({teamId, userId: user?.userId, applicationIds: [applicationId]});
                if(response?.data?.success){
                  toaster.show(false, 'Assigned Successfully');
                  window.location.reload();
                } else {
                  throw new Error(response?.data?.message);
                }
            } catch (error) {
                toaster.show(true, error?.message);
            }
          }
        } else {
          toaster.show(true, 'You dont have permission');
        }
    }

    const getTeamMembers = (teamId) => {
        let members = [];
        teamList?.forEach((team) => {
            if(team?.id === teamId){
                members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}));
            }
        })
        return members;
    }

    const handleInput = (e) => {
        setSearchQuery(e.target.value);
    }

    const data = [
        {
            name: <span className='font-weight-bold fs-13'>Entity Name</span>,
            selector: row => (permission.CUSTOMERS?.DETAILS?.VIEW_CUSTOMER_DETAILS ? <Link to={`/customers/details/${row?.customerCode}?applicationId=${row?.applicationId}`}>{row?.businessName || '-'}</Link> : row?.businessName),
            sortable: false,
            width: '300px',
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>Application ID</span>,
        //     selector: row => (permission.CUSTOMERS?.DETAILS?.VIEW_CUSTOMER_DETAILS ? <Link to={`/customers/details/${row?.customerCode}?applicationId=${row?.applicationId}`}>{row?.applicationId || '-'}</Link> : row?.applicationId),
        //     sortable: false,
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Entity Type</span>,
        //     selector: row => row?.entityType || '-',
        //     sortable: false,
        //     width: '200px'
        // },
        {
            name: <span className='font-weight-bold fs-13'>Product Name</span>,
            selector: row => <>{row?.productName} - {productNameMapping?.[row?.subCategory]}</>,
            sortable: false,
            width: '300px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Sourcing Entity</span>,
            selector: row => <>{row?.createdBy === 'Admin User' ? 'Customer' : row?.createdBy|| '-'}</>,
            sortable: false,
            width: '200px'
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>Requested Amount(Rs.)</span>,
        //     selector: row => row?.amountRequested || '-',
        //     sortable: false,
        //     width: '150px'
        // },
        {
            name: <span className='font-weight-bold fs-13'>Team name / Assigned To</span>,
            cell: (row) => <>
                    <UncontrolledDropdown className="dropdown d-inline-block">
                         <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <Link>{row?.teamName || 'Unassigned'}</Link>
                         </DropdownToggle>
                         <DropdownMenu className="dropdown-menu-end">
                            <div className='d-flex'>
                              <div className='m-2 mr-2'>
                                <input
                                  type="text" 
                                  placeholder="Search..." 
                                  value={searchQuery} 
                                  onChange={handleInput}
                                />
                              </div>
                              {teamListLoading && <div className='d-flex align-items-center me-2'><Spinner size="sm" /></div>}
                            </div>
                            {teamList?.map((team, index) => (
                                <React.Fragment key={`app-col-teams${index}`}>
                                    <DropdownItem onClick={() => handleTeamAssignment(team, row?.applicationId)}>{team?.name}</DropdownItem>
                                </React.Fragment>
                            ))}
                         </DropdownMenu>
                    </UncontrolledDropdown>
                    &nbsp;/ &nbsp;
                    <UncontrolledDropdown className="dropdown d-inline-block">
                         <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <Link>{row?.assignedTo  || 'Unassigned'}</Link>
                         </DropdownToggle>
                         <DropdownMenu className="dropdown-menu-end">
                            {getTeamMembers(row?.teamId)?.map((member, index) => (
                                <React.Fragment key={`app-col-ass-memb${index}`}>
                                    <DropdownItem onClick={() => handleUserAssignment(member, row?.applicationId, row?.teamId)}>{member?.name}</DropdownItem>
                                </React.Fragment>
                            ))}
                         </DropdownMenu>
                    </UncontrolledDropdown>
                </>,
            sortable: false,
            width: '250px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Created Date</span>,
            selector: row => formatDate3(row?.createdAt) || '-',
            sortable: false,
        },
        {
            name: <span className='font-weight-bold fs-13'>Loan Status</span>,
            selector: row => row?.statusCode || '-',
            sortable: false,
            width: '250px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Application Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.status) {
                    case "Re-open":
                        return <span className="badge badge-soft-info"> {row?.status || '-'} </span>;
                    case "ON_HOLD":
                        return <span className="badge badge-soft-secondary"> {row?.status || '-'} </span>;
                    case "DECLINE":
                        return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                    case "INPROGRESS":
                        return <span className="badge badge-soft-warning"> {row?.status || '-'} </span>;
                    case "Open":
                        return <span className="badge badge-soft-primary"> {row?.status || '-'} </span>;
                    case "COMPLETE":
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                }
            },
        },
    ];

    return data;
}

export default Columns;
