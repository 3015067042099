// yourSaga.js
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { Toaster } from '../../components/toaster/Toaster';
import { ApiService } from '../../service/ApiService';
import { 
    getBankStatementReport,
    getBankStatementReportError,
    getBankStatementReportSuccess,
    getBankTransactions,
    getBankTransactionsError,
    getBankTransactionsSuccess,
    getBureauReport, 
    getBureauReportError, 
    getBureauReportSuccess, 
    getInvoiceDetailsAction, 
    getInvoiceDetailsActionFailure, 
    getInvoiceDetailsActionSuccess 
} from './loanAction';

const server = new ApiService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchInvoiceDetails() {
    yield takeEvery(getInvoiceDetailsAction.type, getInvoiceDetails);
}

function* watchBureauReport() {
    yield takeEvery(getBureauReport.type, fetchBureauReport);
}

function* watchBankStatement() {
    yield takeEvery(getBankStatementReport.type, fetchBankStatement);
}

function* watchBankTransactions() {
    yield takeEvery(getBankTransactions.type, fetchBankTransactions);
}



const getInvoiceDataServer = async (payload) => (
    await server.fetchInvoiceDetails(payload).then((response) => (response)).catch((error) => (error))
);

const fetchBureauReportFromServer = async (payload) => (
    await server.fetchBureauReport(payload).then((response) => (response)).catch((error) => (error))
);

const fetchBankStatementFromServer = async (payload) => (
    await server.fetchBankStatement(payload).then((response) => (response)).catch((error) => (error))
);

const fetchBankTransactionsFromServer = async (payload) => (
    await server.fetchBankTransactions().then((response) => (response)).catch((error) => (error))
);


// Worker saga: will be fired on getLenderList actions
function* getInvoiceDetails(action) {
    try {
        const response = yield call(getInvoiceDataServer, action?.payload);
        if(response?.data?.success){
            const {data} = response?.data;
             yield put(getInvoiceDetailsActionSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getInvoiceDetailsActionFailure(error.message));
        toaster.show(true, error?.message);
    }
}

function* fetchBureauReport(action) {
    try {
        const response = yield call(fetchBureauReportFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getBureauReportSuccess(response?.data?.data?.[0]));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getBureauReportError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchBankStatement(action) {
    try {
        const response = yield call(fetchBankStatementFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getBankStatementReportSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getBankStatementReportError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchBankTransactions(action) {
    try {
        const response = yield call(fetchBankTransactionsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getBankTransactionsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getBankTransactionsError(error?.message));
        toaster.show(true, error?.message);
    }
}





export default function* loanSaga(){
    yield all([
        fork(watchInvoiceDetails),
        fork(watchBureauReport),
        fork(watchBankStatement),
        fork(watchBankTransactions)
    ])
};
