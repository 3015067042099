import { createSlice } from "@reduxjs/toolkit";

export const loanAction = createSlice({
  name: 'loanAction',
  initialState: {
    data: {
      action: null,
      loanUid: null,
      productCode: null,
      applicationId: null,
    },
    loading:false,
    invoiceDetails:{},
  },
  reducers: {
    updateLoanAction: (state, action) => {
      state.data = action?.payload;
    },
    getInvoiceDetailsAction: (state, action) => {
      state.loading = true;
    },
    getInvoiceDetailsActionSuccess: (state, action) => {
      state.invoiceDetails = action?.payload;
      state.loading = false;
    },
    getInvoiceDetailsActionFailure: (state, action) => {
      state.loading = false;
    }
  }
});

export const analysisReport = createSlice({
  name: 'bureauReport',
  initialState: {
    loading: false,
    error: null,
    creditReport: null,
    bankStatement: null,
    transactions: null
  },
  reducers: {
    getBureauReport: (state, action) => {
      state.loading = true;
      state.error = null;
      state.creditReport = null;
    },
    getBureauReportSuccess: (state, action) => {
      state.loading = false;
      state.creditReport = action?.payload;
    },
    getBureauReportError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getBankStatementReport: (state, action) => {
      state.loading = true;
      state.error = null;
      state.bankStatement = null;
    },
    getBankStatementReportSuccess: (state, action) => {
      state.loading = false;
      state.bankStatement = action?.payload;
    },
    getBankStatementReportError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getBankTransactions: (state, action) => {
      state.loading = true;
      state.transactions = null;
    },
    getBankTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action?.payload;
    },
    getBankTransactionsError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

export const { 
  updateLoanAction, 
  getInvoiceDetailsAction, 
  getInvoiceDetailsActionSuccess, 
  getInvoiceDetailsActionFailure 
} = loanAction?.actions;

export const {
  getBureauReport,
  getBureauReportSuccess,
  getBureauReportError,
  getBankStatementReport,
  getBankStatementReportSuccess,
  getBankStatementReportError,
  getBankTransactions,
  getBankTransactionsSuccess,
  getBankTransactionsError
} = analysisReport?.actions;

export const loanActionReducer = loanAction?.reducer;
export const analysisReportReducer = analysisReport?.reducer;