import React, { useEffect, useState } from 'react';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { Row, Container, Col, Card, CardHeader, CardBody } from 'reactstrap';
import LoadingButton from '../../../components/Common/LoadingButton';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CustomUncontrolledDropdown from '../../../components/Dropdown/CustomUncontrolledDropdown';
import { useSelector } from 'react-redux';
import { addMemberInTeam, getTeamDetails, getTeamList, getUserList, toggleMemberActivation, updateTeamDetails } from '../../../store/admin/adminData';
import AsyncDropdown from '../../../components/Dropdown/AsyncDropdown';

const Details = () => {

  const { toaster, permission, dispatch } = useClass();
  const { teamId } = useParams();
  const { list: userList, loading: userListLoading } = useSelector((state) => (state?.user));
  const { list, details, loading } = useSelector((state) => (state?.team)); 
  const [teamDetails, setTeamDetails] = useState({})
  const [updatedTeamDetails, setupdatedTeamDetails] = useState({});
  const [memberList, setMemberList] = useState([]);
  const [updatedMemberList, setUpdatedMemberList] = useState([]);
  const [deactivatedMembers, setDeactivatedMembers] = useState([]);

  useEffect(() => {
    dispatch(getTeamDetails(teamId));
  }, []);

  useEffect(() => {
    if(details){
      setTeamDetails(details);
    }
    if(details?.members){
      const members = details?.members?.map((member) => ({...member, label: member?.name, value: member?.userId}));
      setMemberList(members);
    }
  }, [details])

  const handleTeamDetailsChange = (key, value) => {
    if(teamDetails?.[key] != value){
      setupdatedTeamDetails((prev) => ({
        ...prev, 
        [key]: value
      }))
    }
    setTeamDetails((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  const handleOwnerChange = (owner) => {
    return async () => {
      if(owner?.id != teamDetails?.owner){
        const result = await toaster.confirmation('Assign', `Assign ${owner?.name} as the owner of this team.`);
        if(result?.isConfirmed){
          dispatch(updateTeamDetails({teamId, payload: {owner: owner?.id}}));
        }
      }
    }
  }

  const handleParentTeamChange = (parentTeam) => {
    return async () => {
      if(parentTeam?.id !== teamDetails?.parentTeamId){
        const result = await toaster.confirmation('Assign', `Assign ${parentTeam?.name}, as the parent of this team.`);
        if(result?.isConfirmed){
          dispatch(updateTeamDetails({teamId, payload: {parentTeamId: parentTeam?.id}}));
        }
      }
    }
  }

  const handleMembersChange = (key, value) => {
    setUpdatedMemberList(value);
    setMemberList(value);
    teamDetails?.members?.forEach((member) => {
      let exist = false;
      value?.forEach((obj) => {
        if(member?.userId === obj?.userId){
          exist = true;
        }
      })
      if(!exist && !deactivatedMembers?.some((obj) => (obj?.userId == member?.userId))){
        setDeactivatedMembers((prev) => ([
          ...prev,
          {
            "userId": member?.userId,
            "isActive": false
          }
        ]))
      }
    })
  }

  const handleTeamDetailsUpdate = async () => {
    const result = await toaster.confirmation('Update');
    if(result?.isConfirmed){
      if(updatedMemberList?.length > 0){
        const payload = updatedMemberList.filter((member) => (!teamDetails?.members?.some(obj => obj?.id === member?.id)))?.map(member => member?.id);
        dispatch(addMemberInTeam({teamId, payload: {userIds: payload}}));
      }
      if(deactivatedMembers?.length > 0){
        dispatch(toggleMemberActivation({teamId, payload: {members: deactivatedMembers}}));
      }
      if(Object.keys(updatedTeamDetails)?.length > 0){
        dispatch(updateTeamDetails({teamId, payload: updatedTeamDetails}));
      }
    }
  }

  const fetchUserList = (search='', callback=null) => {
    if(permission?.USERS?.LIST?.VIEW_USER_LIST_ALL){
      dispatch(getUserList({search, callback}));
    }
  }

  const fetchTeamList = (search='') => {
    if(permission?.TEAMS?.DETAILS?.UPDATE_TEAM_DETAILS){
      dispatch(getTeamList({search}));
    }
  }

  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="teams" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader>
                  <div className='d-flex gap-4 justify-content-between'>
                    <div className='section-heading'>Team Details</div>
                    <div className='form-check form-switch form-switch-md'>
                      <input 
                        type="checkbox" 
                        className="form-check-input me-2" 
                        checked={teamDetails?.isActive || false}
                        onChange={(e) => handleTeamDetailsChange('isActive', e.target.checked)}
                      />
                      <label className="form-check-label" style={teamDetails?.isActive ? {color: "green"} : {color: "red"}}>
                        {teamDetails?.isActive ? "Active" : "In-Active"}
                      </label>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Name</Row>
                      <Row className='data-value me-4'>{teamDetails?.name}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Owner</Row>
                      <Row className='data-value me-4'>
                        {permission?.TEAMS?.DETAILS?.UPDATE_TEAM_DETAILS ? <CustomUncontrolledDropdown
                          value={teamDetails?.teamowner}
                          options={userList}
                          onClick={handleOwnerChange}
                          loading={userListLoading}
                          handleSearch={fetchUserList}
                        /> : <>{teamDetails?.teamowner}</>}
                      </Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Parent team</Row>
                      <Row className='data-value me-4'>
                        {permission?.TEAMS?.DETAILS?.UPDATE_TEAM_DETAILS ? <CustomUncontrolledDropdown
                          value={teamDetails?.parentTeam?.name || 'none'}
                          options={list?.filter((team) => (team.id != teamId))}
                          onClick={handleParentTeamChange}
                          loading={loading}
                          handleSearch={fetchTeamList}
                        /> : <>{teamDetails?.parentTeam?.name || 'none'}</>}
                      </Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Description</Row>
                      <Row className='data-value me-4'>{teamDetails?.description}</Row>
                    </div>
                    <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                      <Row className='data-key ms-4'>Code</Row>
                      <Row className='data-value me-4'>{teamDetails?.code}</Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>Members</CardHeader>
                <CardBody>
                  <Row>
                    {memberList?.map((member, index) => (
                      <div className='col-lg-2 col-md-6 col-sm-12 mb-2' key={`teammember${index}`}>
                        <div className='form-check form-switch form-switch-md ms-4'>
                          {/* <input 
                            type="checkbox" 
                            className="form-check-input"
                            checked={member?.isActive}
                            onChange={() => {handleMemberDeactivation('memberId', member?.id)}} 
                          /> */}
                          <span className={`data-key`}>
                            {member?.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </Row>
                  {permission?.TEAMS?.DETAILS?.UPDATE_TEAM_MEMBERS && <Row className='mt-4'>
                    <div className='col-lg-4 col-md-6 col-sm-12 mb-2'>
                      <AsyncDropdown 
                        value={memberList}
                        label={'Members'}
                        defaultOptions={userList}
                        handleInput={handleMembersChange}
                        name={'members'}
                        isMulti={true}
                        loadOptions={fetchUserList}
                      />
                    </div>
                  </Row>}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {(permission?.TEAMS?.DETAILS?.UPDATE_TEAM_DETAILS || permission?.TEAMS?.DETAILS?.UPDATE_TEAM_MEMBERS) && <><Row className='mt-4'>
            <Col className='d-flex justify-content-center gap-4'>
                <LoadingButton color={'success'} onClick={handleTeamDetailsUpdate} buttonContent={'Update'} isLoading={loading}/>
            </Col>
          </Row></>}
        </Container>
      </Row>
    </>
  )
}

export default Details;
